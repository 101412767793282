import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import TextBlock from '../components/TextBlock'
import Breadcrumb from '../components/Breadcrumb'

import formatDate from '../utilities/formatDate/formatDate'

const { siteCode, siteConfig } = require('../../site-config')

const {
  newsPage
} = siteConfig[siteCode]

class NewsPostTemplate extends React.Component {
  render () {
    const post = get(this.props, 'data.contentfulNewsPost')
    const pageTitle = `${get(this.props, 'data.site.siteMetadata.siteName')} news / ${post.title}`
    const pageUrl = `${get(this.props, 'data.site.siteMetadata.url')}/news/${post.slug}`
    const authorCred = `${post.author.firstName} ${post.author.lastName}`
    const image = `${post.author.image.fluid.src}`

    return (
      <Page>
        <Helmet>
          {pageTitle && <title>{pageTitle}</title>}
          {post.teaser && <meta name='description' content={post.teaser.teaser} />}
          {pageTitle && <meta property='og:title' content={pageTitle} />}
          {pageUrl && <meta property='og:url' content={pageUrl} />}
          <meta property='og:site_name' content={get(this.props, 'data.site.siteMetadata.siteName')} />
        </Helmet>
        <Breadcrumb items={[
          {
            title: newsPage.title,
            path: `/${newsPage.path}`
          },
          {
            title: post.title
          }
        ]}
        />
        <Page.Panel noPadding>
          <TextBlock
            author={authorCred}
            date={formatDate(post.date)}
            headline={post.title}
            bodyText={post.body}
            image={image}
          />
        </Page.Panel>
      </Page>
    )
  }
}

export default NewsPostTemplate

export const pageQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    site {
      siteMetadata {
        siteName
        url
      }
    }
    contentfulNewsPost(slug: { eq: $slug }) {
      slug
      title
      date
      author { 
        firstName
        lastName
        image {
          fluid(
            quality: 60
            maxWidth: 300
            maxHeight: 300
            resizingBehavior: THUMB
          ) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      teaser {
        teaser
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      featured
    }
  }
`
